const sliderData =[
    {
      "id": 1,
      "team1": "Majhi Mumbai",
      "team2": "Tiigers Of Kolkata",
      "logo1": "images/majhiMumbaiLogo.png",
      "logo2": "/images/kolkataTiger1.png",
      "score1": "58/9",
      "overs1": "10.0 OV",
      "score2": "60/0",
      "overs2": "7.4 OV",
      "stadium": "Dadoji Kondadev Stadium",
      "liveStatus": "Live",
      "winMsg" : "WinMsg",
      "category" : "Individual Match"
    },
    {
      "id": 2,
      "team1": "CHENNAI SINGAMS",
      "team2": "Tiigers Of Kolkata",
      "logo1": "images/majhiMumbaiLogo.png",
      "logo2": "/images/kolkataTiger1.png",
      "score1": "58/9",
      "overs1": "10.0 OV",
      "score2": "60/0",
      "overs2": "7.4 OV",
      "stadium": "Dadoji Kondadev Stadium",
      "liveStatus": "Live",
       "winMsg" : "WinMsg",
      "city" : "Kolkata",
      "category" : "Testing"
    },
    {
      "id": 3,
      "team1": "SRINAGAR KE VEER",
      "team2": "Tiigers Of Kolkata",
      "logo1": "images/majhiMumbaiLogo.png",
      "logo2": "/images/kolkataTiger1.png",
      "score1": "58/9",
      "overs1": "10.0 OV",
      "score2": "60/0",
      "overs2": "7.4 OV",
      "stadium": "Dadoji Kondadev Stadium",
      "liveStatus": "Live",
      "category" : "Individual Match"
    },
    {
      "id": 4,
      "team1": "KVN BANGALORE STRIKERS",
      "team2": "Tiigers Of Kolkata",
      "logo1": "images/majhiMumbaiLogo.png",
      "logo2": "/images/kolkataTiger1.png",
      "score1": "58/9",
      "overs1": "10.0 OV",
      "score2": "60/0",
      "overs2": "7.4 OV",
      "stadium": "Dadoji Kondadev Stadium",
      "liveStatus": "Live",
      "category" : "Individual Match"
    },
    {
      "id": 5,
      "team1": "FALCON RISERS HYDERABAD",
      "team2": "Tiigers Of Kolkata",
      "logo1": "images/majhiMumbaiLogo.png",
      "logo2": "/images/kolkataTiger1.png",
      "score1": "58/9",
      "overs1": "10.0 OV",
      "score2": "60/0",
      "overs2": "7.4 OV",
      "stadium": "Dadoji Kondadev Stadium",
      "liveStatus": "Live",
      "category" : "Individual Match"
    },
    {
      "id": 6,
      "team1": "Majhi Mumbai",
      "team2": "Tiigers Of Kolkata",
      "logo1": "images/majhiMumbaiLogo.png",
      "logo2": "images/kolkataTiger1.png",
      "score1": "58/9",
      "overs1": "10.0 OV",
      "score2": "60/0",
      "overs2": "7.4 OV",
      "stadium": "Dadoji Kondadev Stadium",
      "liveStatus": "Live",
      "category" : "Individual Match"
    },
    {
      "id": 7,
      "team1": "FALCON RISERS HYDERABAD",
      "team2": "Tiigers Of Kolkata",
      "logo1": "images/majhiMumbaiLogo.png",
      "logo2": "images/kolkataTiger1.png",
      "score1": "58/9",
      "overs1": "10.0 OV",
      "score2": "60/0",
      "overs2": "7.4 OV",
      "stadium": "Dadoji Kondadev Stadium",
      "liveStatus": "Live",
      "category" : "Individual Match"
    },
    {
      "id": 8,
      "team1": "SRINAGAR KE VEER",
      "team2": "Tiigers Of Kolkata",
      "logo1": "images/majhiMumbaiLogo.png",
      "logo2": "images/kolkataTiger1.png",
      "score1": "58/9",
      "overs1": "10.0 OV",
      "score2": "60/0",
      "overs2": "7.4 OV",
      "stadium": "Dadoji Kondadev Stadium",
      "liveStatus": "Live",
      "category" : "Individual Match"
    },
    {
      "id": 9,
      "team1": "CHENNAI SINGAMS",
      "team2": "Tiigers Of Kolkata",
      "logo1": "images/majhiMumbaiLogo.png",
      "logo2": "images/kolkataTiger1.png",
      "score1": "58/9",
      "overs1": "10.0 OV",
      "score2": "60/0",
      "overs2": "7.4 OV",
      "stadium": "Dadoji Kondadev Stadium",
      "liveStatus": "Live",
      "category" : "Individual Match"
    },
    {
      "id": 10,
      "team1": "Tiigers Of Kolkata",
      "team2": "KVN BANGALORE STRIKER",
      "logo1": "images/majhiMumbaiLogo.png",
      "logo2": "images/kolkataTiger1.png",
      "score1": "58/9",
      "overs1": "10.0 OV",
      "score2": "60/0",
      "overs2": "7.4 OV",
      "stadium": "Dadoji Kondadev Stadium",
      "liveStatus": "Live",
      "category" : "Individual Match"
    },
    {
      "id": 11,
      "team1": "CHENNAI SINGAMS",
      "team2": "Tiigers Of Kolkata",
      "logo1": "images/majhiMumbaiLogo.png",
      "logo2": "images/kolkataTiger1.png",
      "score1": "58/9",
      "overs1": "10.0 OV",
      "score2": "60/0",
      "overs2": "7.4 OV",
      "stadium": "Dadoji Kondadev Stadium",
      "liveStatus": "Live",
      "category" : "Individual Match"
    },
    {
      "id": 12,
      "team1": "CHENNAI SINGAMS",
      "team2": "Tiigers Of Kolkata",
      "logo1": "images/majhiMumbaiLogo.png",
      "logo2": "images/kolkataTiger1.png",
      "score1": "58/9",
      "overs1": "10.0 OV",
      "score2": "60/0",
      "overs2": "7.4 OV",
      "stadium": "Dadoji Kondadev Stadium",
      "liveStatus": "Live",
      "category" : "Individual Match"
    }
]
export default sliderData;
