export const battingStatsData = [
  { score: 190, label: "Matches" },
  { score: 132, label: "Innings" },
  { score: 29, label: "Not out" },
  { score: 1073, label: "Runs" },
  { score: 49, label: "Highest Runs" },
  { score: 10.42, label: "Avg" },
  { score: 113.07, label: "SR" },
  { score: 6, label: "30s" },
  { score: 0, label: "50s" },
  { score: 0, label: "100s" },
  { score: 41, label: "6s" },
  { score: 21, label: "Ducks" },
  { score: 109, label: "Won" },
  { score: 84, label: "Loss" },
];

export const bowlingStatsData = [
    { score: 190, label: "Matches" },
    { score: 127, label: "Innings" },
    { score: 272.1, label: "Overs" },
    { score: 1, label: "Miadens" },
    { score: 116, label: "Wickets" },
    { score: 2183, label: "Runs" },
    { score: 3/2, label: "Best Bowling" },
    { score: 12, label: "3 Wickets" },
    { score: 0, label: "5 Wickets" },
    { score: 0.82, label: "Economy" },
    { score: 14.08, label: "SR" },
    { score: 18.82, label: "Avg" },
    { score: 134, label: "Wides" },
    { score: 24, label: "NoBalls" },
    { score: 646, label: "Dot Balls" },
    { score: 180, label: "4s" },
    { score: 50, label: "6s" },
  ];

  export const fieldingStatsData = [
    { score: 190, label: "Matches" },
    { score: 79, label: "Catches" },
    { score: 2, label: "Caught behind" },
    { score: 14, label: "Run Outs" },
    { score: 3, label: "Stumpings" },
    { score: 10, label: "Assisted Run Outs" },
    { score: 6, label: "Bye Runs (WK)" },
  ];

  export const captainStatsData = [
    { score: 5, label: "Matches" },
    { score: 3, label: "Toss Won" },
    { score: 20.00, label: "Win Per" },
    { score: 80.00, label: "Loss Per" },
  ];

  export const infoTable = [
      { label: "LOCATION", value: "Mumbai" },
      { label: "DOB", value: "1986-01-16" },
      { label: "PLAYING ROLE", value: "All-Rounder" },
      { label: "BATTING STYLE", value: "RHB" },
    ];
